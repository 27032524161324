import React, { PropsWithChildren } from 'react';
import {makeStyles} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  accordionHeader: {
    backgroundColor: theme.palette.divider,
  },
  headerText: {
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
  },
  expandIcon: {
    color: theme.palette.text.primary,
  },
  fullOpacity: {
    opacity: `1 !important`,
  },
  showPaddingTop: {
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3.5),
    },
  },
  showPaddingBottom: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3.5),
    },
  },
}));

export interface AccordionProps {
  id: string;
  headerText: string | JSX.Element;
  totalComponent?: React.ReactNode;
  expanded?: boolean;
  allowExpand?: boolean;
  showPaddingTop?: boolean;
  showPaddingBottom?: Boolean;
}

export const AccordionTemplate: React.FC<PropsWithChildren<AccordionProps>> = ({
  id,
  headerText,
  totalComponent,
  children,
  expanded = true,
  allowExpand = true,
  showPaddingTop = false,
  showPaddingBottom = false,
}: PropsWithChildren<AccordionProps>) => {
  const classes = useStyles();

  return (
    <div
      className={classNames({
        [classes.showPaddingTop]: showPaddingTop,
        [classes.showPaddingBottom]: showPaddingBottom,
      })}
    >
      <Accordion defaultExpanded={expanded && allowExpand}>
        <AccordionSummary
          expandIcon={
            allowExpand && <ExpandMoreIcon className={classes.expandIcon} />
          }
          aria-controls="activeBasket-content"
          id={`${id}-header`}
          className={classNames(classes.accordionHeader, {
            [classes.fullOpacity]: !allowExpand,
          })}
          disabled={!allowExpand}
        >
          <Typography className={classes.headerText}>{headerText}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
        {totalComponent}
      </Accordion>
    </div>
  );
};
export default AccordionTemplate;
