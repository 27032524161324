import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Typography, Grid, Skeleton } from "@mui/material";

import {
  ActiveBasketExtra,
  ActiveBasketExtraOption,
  Basket,
} from "../interfaces/bookingSummary";
import classnames from "classnames";
import { FHCurrency } from "../FHCurrency";
import moment from "moment";
import { FHTooltip } from "../FHTooltip";
import AccordionTemplate from "../AccordionTemplate";

const useStyles = (isReadOnly?: boolean) =>
  makeStyles((theme) => ({
    headerText: {
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
    },
    headerDate: {
      marginBottom: theme.spacing(2),
    },
    justifyEnd: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    extraOptionNameContainer: {
      display: "flex",
      flex: 1,
      minWidth: 0,
      paddingRight: theme.spacing(2),
    },
    extraOptionName: {
      paddingLeft: theme.spacing(isReadOnly ? 0.5 : 2),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    extraOptionQuantity: {
      display: "inline",
      whiteSpace: "nowrap",
    },
    total: {
      ...(!isReadOnly && {
        borderTop: `2px solid ${theme.palette.text.primary}`,
      }),
      backgroundColor: isReadOnly
        ? theme.palette.divider
        : theme.palette.action.active,
      padding: theme.spacing(1, 2),
    },
    cartExpiryIcon: {
      display: "flex",
      paddingRight: theme.spacing(0.3),
    },
    cartExpiryLabel: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      backgroundColor: theme.palette.action.active,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    removeIcon: {
      display: "flex",
      marginLeft: theme.spacing(1),
      "&:hover": {
        cursor: "pointer",
      },
    },
    inlineSkeleton: {
      display: "inline-block",
      marginLeft: theme.spacing(1),
    },
    containerContent: {
      padding: theme.spacing(1, 2, 2),
    },
    bookingSummaryTitleContainer: {
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.divider,
    },
    bookingSummaryTitle: {
      fontSize: "18px",
      lineHeight: "35px",
    },
    bookingReference: {
      lineHeight: "35px",
    },
    totalContainer: {
      ".MuiGrid-root": {},
    },
    totalRow: {
      margin: theme.spacing(1, 0),
    },
    totalRowPrice: {
      fontSize: 18,
    },
    paymentInfoRow: {
      "& + $paymentInfoRow": {
        marginTop: theme.spacing(1),
      },
      "& span": {
        display: "block",
        fontSize: theme.typography.caption.fontSize,
      },
    },
    lowDepositRow: {
      display: "flex",
      alignItems: "center",
    },
    tooltipIcon: {
      height: 24,
      marginLeft: theme.spacing(0.5),
    },
  }))();

export type ActiveBasketTitleProps = {
  title: string;
  basketItemAmount: number;
  itemsLabel: string;
  loading?: boolean;
};

export const ActiveBasketTitle: React.FC<ActiveBasketTitleProps> = ({
  title,
  basketItemAmount,
  itemsLabel,
  loading,
}: ActiveBasketTitleProps) => (
  <>
    {loading ? (
      <Skeleton width="40%" />
    ) : (
      <strong>
        {title} ({basketItemAmount} {itemsLabel}){" "}
      </strong>
    )}
  </>
);

export type ActiveBasketHeaderProps = {
  loading?: boolean;
  title: string;
  bookingRef: string;
  isReadOnly?: boolean;
};

export const ActiveBasketHeader: React.FC<ActiveBasketHeaderProps> = ({
  loading,
  title,
  bookingRef,
  isReadOnly,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.bookingSummaryTitleContainer}>
      <Grid container spacing={1}>
        <Grid item xs>
          <Typography className={classes.bookingSummaryTitle}>
            {loading ? (
              <Skeleton width={80} />
            ) : isReadOnly ? (
              <strong>{title}</strong>
            ) : (
              title
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant={isReadOnly ? "body2" : "caption"}
            className={classes.bookingReference}
          >
            {loading ? <Skeleton width={80} /> : bookingRef}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

type ActiveBasketTotalProps = {
  loading?: boolean;
  totalLabel: string | null;
  totalPrice: number;
  currencySymbol?: string;
  paymentsReceived?: {
    label?: string;
    price?: number;
  };
  cardPayment?: {
    label?: string;
    date?: Date;
    price?: number;
  };
  gvPayment?: {
    label?: string;
    date?: Date;
    price?: number;
  };
  isReadOnly?: boolean;
  lowDepositMessage?: {
    label?: string;
    value?: number;
    tooltipIcon?: JSX.Element;
    tooltipMessage?: JSX.Element;
  };
};

export const ActiveBasketTotal: React.FC<ActiveBasketTotalProps> = ({
  loading,
  totalLabel,
  totalPrice,
  currencySymbol = "£",
  paymentsReceived,
  cardPayment,
  gvPayment,
  isReadOnly,
  lowDepositMessage,
}) => {
  const classes = useStyles(isReadOnly);

  return (
    <>
      <Grid container className={classes.total}>
        <Grid item xs>
          <Typography className={classes.headerText}>
            {loading ? <Skeleton width={80} /> : <strong>{totalLabel}</strong>}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={`${classes.headerText} ${classes.totalRowPrice}`}
          >
            {loading ? (
              <Skeleton width={50} />
            ) : (
              <strong>
                <FHCurrency currencySymbol={currencySymbol}>
                  {totalPrice}
                </FHCurrency>
              </strong>
            )}
          </Typography>
        </Grid>
        {lowDepositMessage && (
          <Grid container className={classes.totalContainer}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.totalRow}
            >
              <Grid item className={classes.lowDepositRow}>
                {loading ? (
                  <Skeleton width={80} />
                ) : (
                  <>
                    <Typography>{lowDepositMessage.label}</Typography>
                    <Typography className={classes.tooltipIcon}>
                      <FHTooltip
                        title={<>{lowDepositMessage.tooltipMessage}</>}
                        placement="top"
                        arrow
                      >
                        <>{lowDepositMessage.tooltipIcon}</>
                      </FHTooltip>
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item>
                <Typography>
                  {loading ? (
                    <Skeleton width={50} />
                  ) : (
                    <FHCurrency price={lowDepositMessage.value} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!!paymentsReceived?.price && (
          <Grid container className={classes.totalContainer}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.totalRow}
            >
              <Grid item>
                {paymentsReceived?.label &&
                  (loading ? (
                    <Skeleton width={80} />
                  ) : (
                    <Typography>
                      <strong>{paymentsReceived.label}</strong>
                    </Typography>
                  ))}
              </Grid>
              <Grid item>
                <Typography>
                  <strong className={classes.totalRowPrice}>
                    {loading ? (
                      <Skeleton width={50} />
                    ) : (
                      <FHCurrency price={paymentsReceived.price} />
                    )}
                  </strong>
                </Typography>
              </Grid>
            </Grid>

            {!!cardPayment?.price && (
              <Grid
                container
                justifyContent="space-between"
                className={classes.paymentInfoRow}
              >
                <Grid item>
                  {cardPayment?.label &&
                    cardPayment?.date &&
                    (loading ? (
                      <Skeleton width={80} />
                    ) : (
                      <Typography>
                        {cardPayment.label}
                        <span>
                          {moment(cardPayment.date).format("DD/MM/YY")}
                        </span>
                      </Typography>
                    ))}
                </Grid>
                <Grid item>
                  <Typography>
                    {loading ? (
                      <Skeleton width={50} />
                    ) : (
                      <FHCurrency price={cardPayment?.price} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {!!gvPayment?.price && gvPayment.price > 0 && (
              <Grid
                container
                justifyContent="space-between"
                className={classes.paymentInfoRow}
              >
                <Grid item>
                  {gvPayment?.label &&
                    gvPayment?.date &&
                    (loading ? (
                      <Skeleton width={80} />
                    ) : (
                      <Typography>
                        {gvPayment.label}
                        <span>{moment(gvPayment.date).format("DD/MM/YY")}</span>
                      </Typography>
                    ))}
                </Grid>
                <Grid item>
                  <Typography>
                    {loading ? (
                      <Skeleton width={50} />
                    ) : (
                      <FHCurrency price={gvPayment.price} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export type ActiveBasketProps = {
  activeBasketTitle: string;
  itemsLabel: string;
  cartExpiryIcon: JSX.Element;
  cartExpiryLabel: string;
  cartExpiryMinutesLabel: string;
  showCartExpiry?: boolean;
  removeIcon: JSX.Element;
  onRemoveIconClicked: (extraId: string) => void;
  totalLabel: string;
  emptyBasketLabel: string;
  activeBasket: Basket;
  loading?: boolean;
  expandBasket?: boolean;
  accordionContainer?: boolean;
  dayLabel?: string;
  isReadOnly?: boolean;
};

export const ActiveBasket: React.FC<ActiveBasketProps> = ({
  activeBasketTitle,
  itemsLabel,
  cartExpiryIcon,
  cartExpiryLabel,
  cartExpiryMinutesLabel,
  showCartExpiry,
  removeIcon,
  onRemoveIconClicked,
  totalLabel,
  emptyBasketLabel,
  activeBasket,
  loading,
  expandBasket = true,
  accordionContainer = true,
  dayLabel,
  isReadOnly,
}: PropsWithChildren<ActiveBasketProps>) => {
  const classes = useStyles(isReadOnly);
  const cartExpiryMessage = `${cartExpiryLabel} ${activeBasket.cartExpiry} ${cartExpiryMinutesLabel}`;

  const formatDate = (extra: ActiveBasketExtra) => {
    const day =
      extra.calendarDate && moment(extra.calendarDate).format("DD/MM");
    const time = extra.aMPM && extra.aMPM.toUpperCase();
    return `${dayLabel ? dayLabel + " " : ""}${day || ""}${
      !!day && !!time ? " - " : ""
    }${time || ""}`;
  };

  const ContainerWrapper = ({ children }: { children: React.ReactNode }) =>
    accordionContainer ? (
      // TODO: this accordion is probably not needed anymore. Remove
      <AccordionTemplate
        id="activeBasket"
        headerText={
          <>
            {activeBasketTitle}{" "}
            {loading ? (
              <Skeleton className={classes.inlineSkeleton} width={70} />
            ) : (
              `(${activeBasket.extras.length || 0} ${itemsLabel})`
            )}
          </>
        }
        allowExpand={expandBasket}
        totalComponent={
          <ActiveBasketTotal
            {...{
              loading,
              totalLabel,
              totalPrice: activeBasket.totalPrice,
            }}
          />
        }
      >
        {children}
      </AccordionTemplate>
    ) : (
      <div>{children}</div>
    );

  return (
    <ContainerWrapper>
      {activeBasket?.extras.length > 0 ? (
        <Grid container spacing={2} className={classes.containerContent}>
          {showCartExpiry && !isReadOnly && (
            <Grid item xs={12}>
              <React.Fragment>
                {loading ? (
                  <Skeleton width={100} />
                ) : (
                  <div className={classes.cartExpiryLabel}>
                    <div className={classes.cartExpiryIcon}>
                      {cartExpiryIcon}
                    </div>
                    <Typography variant="body2">{cartExpiryMessage}</Typography>
                  </div>
                )}
              </React.Fragment>
            </Grid>
          )}

          {activeBasket.extras.map((extra: ActiveBasketExtra, index) => (
            <Grid
              item
              container
              alignItems="center"
              spacing={1}
              key={`extra-${index}`}
            >
              {activeBasket.extras.length > 1 && index !== 0 && (
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography className={classes.headerText}>
                  {loading ? <Skeleton width={"35%"} /> : extra.extraName}
                </Typography>
                {(extra.calendarDate || extra.aMPM) && (
                  <Typography
                    className={isReadOnly ? undefined : classes.headerDate}
                  >
                    {loading ? (
                      <Skeleton width={"30%"} />
                    ) : (
                      <strong>{formatDate(extra)}</strong>
                    )}
                  </Typography>
                )}
              </Grid>
              {loading ? (
                <Grid item xs={12}>
                  <Skeleton />
                  <Skeleton />
                </Grid>
              ) : (
                extra?.reservationExtraOptions?.map(
                  (option: ActiveBasketExtraOption, index) => (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      key={`extra-option-${index}`}
                    >
                      <Grid item className={classes.extraOptionNameContainer}>
                        <Typography
                          variant="body1"
                          className={classes.extraOptionName}
                        >
                          {option.extraOptionName}
                        </Typography>
                        {option.quantity > 1 && (
                          <Typography
                            variant="body1"
                            className={classes.extraOptionQuantity}
                          >
                            <FHCurrency>{option.extraOptionPrice}</FHCurrency>{" "}
                            &times; {option.quantity}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <div className={classes.justifyEnd}>
                          <Typography
                            className={classnames(classes.headerText)}
                          >
                            <FHCurrency>{option.totalPrice}</FHCurrency>
                          </Typography>
                          {removeIcon && !isReadOnly && (
                            <div
                              className={classes.removeIcon}
                              onClick={() =>
                                onRemoveIconClicked &&
                                onRemoveIconClicked(
                                  option.cabinReservationExtraId
                                )
                              }
                            >
                              {removeIcon}
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )
                )
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} className={classes.containerContent}>
          <Grid item xs>
            <Typography variant="body1">{emptyBasketLabel}</Typography>
          </Grid>
        </Grid>
      )}
    </ContainerWrapper>
  );
};
