import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Grid, Card, Typography } from "@mui/material";
import { FHButton } from "../FHButton";
import { useIsMobile } from "../../../hooks/hooks";
import { MobileDrawerCard } from "../ModalDrawerCard";
import classnames from "classnames";
import { FHAuthContainer } from "../FHAuthContainer";

const useStyles = makeStyles((theme) => ({
  disableGrid: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  basketActions: {
    padding: theme.spacing(1),
  },
  headerContainer: {
    fontSize: 18,
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.divider,
  },
  footerContainer: {
    width: "100%",
  },
  buttonRoot: {
    position: "relative",
    padding: 0,
    marginRight: theme.spacing(1.5),
  },
  disableButtonRoot: {
    opacity: 0.2,
  },
  totalItems: {
    fontSize: 18,
    position: "absolute",
    right: theme.spacing(-1),
    top: theme.spacing(-1.5),
    backgroundColor: theme.palette.primary.dark,
    width: 22,
    height: 22,
    borderRadius: "50%",
    color: theme.palette.background.default,
    lineHeight: `${theme.spacing(1.8)}`,
  },
  bodyContainer: {
    width: "100%",
  },
  bodyContainerScroll: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "calc(100vh - 205px)",
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc(100vh - 180px)",
    },
  },
}));

export type BookingSummaryContainerProps = {
  primaryCallToActionText?: string;
  isFormSubmissionRequired?: boolean;
  primaryCallToActionClick?: () => void;
  secondaryCallToActionText?: string;
  secondaryCallToActionClick?: () => void;
  onGvBasketSubmit?: () => void;
  loading?: boolean;
  disabled?: boolean;
  mobileDrawerButtonIcon?: React.ReactNode;
  hideCtaButtons?: boolean;
  expandedSummary?: React.ReactNode;
  activeBasketContent?: React.ReactNode;
  expandSummary?: boolean;
  activeBasketTitle?: React.ReactNode;
  activeBasketHeader?: React.ReactNode;
  activeBasketFooter?: React.ReactNode;
  showDrawerOnMobile?: boolean;
  formId?: string;
  disableGVSummary?: boolean;
  isReadOnly?: boolean;
  totalItems?: number;
  stickyContainer?: boolean;
  proceedToConfirmationCTALabel?: string;
};

export const BookingSummaryContainer: React.FC<BookingSummaryContainerProps> =
  ({
    primaryCallToActionText,
    isFormSubmissionRequired = false,
    primaryCallToActionClick,
    secondaryCallToActionText,
    secondaryCallToActionClick,
    onGvBasketSubmit,
    loading,
    disabled,
    mobileDrawerButtonIcon,
    hideCtaButtons = false,
    expandedSummary,
    activeBasketContent,
    activeBasketHeader,
    activeBasketTitle,
    activeBasketFooter,
    showDrawerOnMobile = true,
    formId,
    disableGVSummary = true,
    isReadOnly,
    totalItems,
    stickyContainer,
  }: PropsWithChildren<BookingSummaryContainerProps>) => {
    const classes = useStyles();
    const isMobile = useIsMobile() && showDrawerOnMobile;

    const renderActiveBasketTitle = () =>
      activeBasketTitle && (
        <Typography className={classes.headerContainer}>
          {activeBasketTitle}
        </Typography>
      );
    const isDisabled = loading || disabled;

    const renderFooter = () => (
      <Grid item xs={12} className={classes.footerContainer}>
        {activeBasketFooter && activeBasketFooter}
        {!loading &&
          !hideCtaButtons &&
          (!!primaryCallToActionClick ||
            !!secondaryCallToActionClick ||
            isFormSubmissionRequired) && (
            <Grid container spacing={2} className={classes.basketActions}>
              <Grid item xs={12}>
                {isFormSubmissionRequired && primaryCallToActionText && formId && (
                  <>
                    {(onGvBasketSubmit && (
                      <FHAuthContainer onSubmit={onGvBasketSubmit}>
                        <FHButton
                          fullWidth
                          disabled={isDisabled}
                          type="submit"
                          fhSize="md"
                        >
                          {primaryCallToActionText}
                        </FHButton>
                      </FHAuthContainer>
                    )) || (
                      <FHButton
                        fullWidth
                        disabled={isDisabled}
                        type="submit"
                        form={formId}
                        fhSize="md"
                      >
                        {primaryCallToActionText}
                      </FHButton>
                    )}
                  </>
                )}
                {!isFormSubmissionRequired && primaryCallToActionText && (
                  <FHButton
                    fhStyle={"primary"}
                    fullWidth
                    disabled={isDisabled}
                    fhSize="md"
                    onClick={
                      primaryCallToActionClick && primaryCallToActionClick
                    }
                  >
                    {primaryCallToActionText}
                  </FHButton>
                )}
              </Grid>
              <Grid item xs={12}>
                {secondaryCallToActionText && (
                  <FHButton
                    fhStyle={"secondary"}
                    fhSize="md"
                    fullWidth
                    disabled={isDisabled}
                    onClick={
                      secondaryCallToActionClick && secondaryCallToActionClick
                    }
                  >
                    {secondaryCallToActionText}
                  </FHButton>
                )}
              </Grid>
            </Grid>
          )}
      </Grid>
    );

    const Wrapper = ({
      children,
      isReadOnly,
    }: {
      children: React.ReactNode;
      isReadOnly?: boolean;
    }) => {
      const [openMobileDrawer, setOpenMobileDrawer] = React.useState(false);
      const showCartItems = totalItems && totalItems > 0 ? true : false;
      return isMobile && !isReadOnly ? (
        <>
          {/* it's essential that button is in here separately from everything else to avoid uneccessary re-rendering */}
          <IconButton
            classes={{
              root: classes.buttonRoot,
              disabled: classes.disableButtonRoot,
            }}
            onClick={() => setOpenMobileDrawer(true)}
            disabled={!showCartItems}
          >
            {showCartItems && (
              <Typography variant="h1" className={classes.totalItems}>
                {totalItems}
              </Typography>
            )}
            {mobileDrawerButtonIcon}
          </IconButton>
          <MobileDrawerCard
            open={!!openMobileDrawer}
            title={activeBasketTitle}
            onOpen={() => null}
            onClose={() => setOpenMobileDrawer(false)}
            actions={<>{renderFooter()}</>}
            noSpacing
            displayCardActionsAsBlock
          >
            {children}
          </MobileDrawerCard>
        </>
      ) : (
        <Card>{children}</Card>
      );
    };

    const BasketHeaderGroup = () => (
      <>
        {activeBasketHeader && (
          <Grid item xs={12}>
            {activeBasketHeader}
          </Grid>
        )}
      </>
    );

    const BasketExpandedSummaryGroup = () => (
      <>
        {expandedSummary && (
          <Grid item xs={12}>
            {expandedSummary}
          </Grid>
        )}
      </>
    );

    return (
      <Wrapper isReadOnly={isReadOnly}>
        <Grid
          container
          className={isDisabled && disableGVSummary ? classes.disableGrid : ""}
        >
          {isReadOnly ? (
            !isMobile && <BasketHeaderGroup />
          ) : (
            <BasketHeaderGroup />
          )}

          <div
            className={classnames(classes.bodyContainer, {
              [classes.bodyContainerScroll]: stickyContainer,
            })}
          >
            {isReadOnly ? (
              !isMobile && <BasketExpandedSummaryGroup />
            ) : (
              <BasketExpandedSummaryGroup />
            )}

            {activeBasketContent && (
              <Grid item xs={12}>
                {isReadOnly
                  ? renderActiveBasketTitle()
                  : !isMobile && renderActiveBasketTitle()}
                {activeBasketContent}
              </Grid>
            )}
          </div>
          {isReadOnly ? renderFooter() : !isMobile && renderFooter()}
        </Grid>
      </Wrapper>
    );
  };
