import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { FHCurrency } from "../FHCurrency";
import FHButton from "../FHButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
    fontSize: 18,
  },
  itemsContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  rightCol: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  price: {
    fontSize: 18,
  },
  removeIcon: {
    padding: theme.spacing(0.4),
    marginLeft: theme.spacing(2),
    width: 30,
    height: "auto",
    minWidth: "auto",
    "& svg": {
      margin: 0,
    },
    "&:before": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  basketItem: {
    paddingRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      wordBreak: "break-word",
    },
    "& strong": {
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    "& span": {
      [theme.breakpoints.down("sm")]: {
        wordBreak: "break-word",
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },
}));

type BasketItems = {
  name: string;
  price: number;
  basketItemId: number;
  email?: string;
};

export type GiftVoucherBasketItemsProps = {
  basketItemsTitle?: string | null;
  basketItems: BasketItems[];
  removeIcon?: JSX.Element;
  onRemoveItem?: (basketItemId: number) => void;
  currencySymbol?: string;
};

export const GiftVoucherBasketItems: React.FC<GiftVoucherBasketItemsProps> = ({
  basketItemsTitle,
  basketItems,
  removeIcon,
  onRemoveItem,
  currencySymbol,
}: PropsWithChildren<GiftVoucherBasketItemsProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        <strong>{basketItemsTitle}</strong>
      </Typography>
      {basketItems.map((basketItem, i) => (
        <div key={i} className={classes.itemsContainer}>
          <Typography className={classes.basketItem}>
            {basketItem?.email ? (
              <strong>{basketItem.name}</strong>
            ) : (
              basketItem.name
            )}
            {basketItem.email && <span> {basketItem.email}</span>}
          </Typography>
          <div className={classes.rightCol}>
            <Typography className={classes.price}>
              <strong>
                <FHCurrency currencySymbol={currencySymbol}>
                  {basketItem.price}
                </FHCurrency>
              </strong>
            </Typography>
            {removeIcon && (
              <FHButton
                fhStyle="secondary"
                fullWidth={false}
                className={classes.removeIcon}
                onClick={() => {
                  onRemoveItem && onRemoveItem(basketItem.basketItemId);
                }}
              >
                {removeIcon}
              </FHButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
